import UrlConfig from "config/url.config";
import { RouteMenu } from "model/other";
import setting_router from "./setting.router";
import Dashboard from "screen/Dashboard";

const menu: Array<RouteMenu> = [
  {
    path: UrlConfig.HOME,
    exact: true,
    title: "Trang chủ",
    icon: "icon-arrow-left",
    component: Dashboard,
    key: "1",
    isShow: true,
    header: null,
    subMenu: [],
  },
  {
    path: UrlConfig.SETTINGS,
    exact: true,
    title: "Cài đặt",
    icon: "icon-Button",
    component: null,
    key: "2",
    isShow: true,
    header: null,
    subMenu: setting_router,
  },
];

export default menu;
