import { Button, Form, Input, Row } from "antd";
import { StylesWrapperLeft, StylesWrapperRight } from "./styles";
import yody_fashion_tech from "assets/img/yody_fashion_tech.png";
import stars from "assets/img/stars.png";
import team_members from "assets/img/team_members.png";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

type IProps = {
  onFinish: (values: any) => void;
  loading: boolean;
};

export const LoginWeb = (props: IProps) => {
  const { onFinish, loading } = props;
  return (
    <Row gutter={24} style={{ width: "100%", height: "100%" }}>
      <StylesWrapperRight span={12}>
        <div
          className="container-right"
          style={{
            marginLeft: "auto !important",
            marginRight: "auto !important",
          }}>
          <div className="login">
            <div className="header">
              <img src={yody_fashion_tech} alt="fashionTech" />
            </div>
            <div className="yody-hello">YODY XIN CHÀO!</div>
            <div className="login-title">
              Chào mừng trở lại! Vui lòng nhập thông tin chi tiết của bạn.
            </div>
            <div className="login-group">
              <Form
                className="login-form"
                layout="vertical"
                initialValues={{ username: "", password: "" }}
                onFinish={onFinish}>
                <Form.Item
                  className="row-form"
                  label="Tên đăng nhập"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Tên đăng nhập không được bỏ trống",
                    },
                    {
                      pattern: /^[a-zA-Z0-9]{4,20}$/,
                      message: "Tên đăng nhập sai định dạng",
                    },
                  ]}>
                  <Input
                    className="username"
                    prefix={<UserOutlined style={{ color: "#c5c5c5", marginRight: 8 }} />}
                    size="large"
                    disabled={loading}
                    placeholder="Tên đăng nhập"
                  />
                </Form.Item>

                <Form.Item
                  className="row-form"
                  label="Mật khẩu"
                  name="password"
                  rules={[{ required: true, message: "Mật khẩu không được bỏ trống" }]}>
                  <Input.Password
                    prefix={<LockOutlined style={{ color: "#c5c5c5", marginRight: 8 }} />}
                    disabled={loading}
                    size="large"
                    placeholder="Mật khẩu"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    loading={loading}
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="login-form-button">
                    Đăng nhập
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className="copy-right">© 2021 Copyright Yody Fashion Tech. All Rights Reserved</div>
        </div>
      </StylesWrapperRight>
      <StylesWrapperLeft span={12}>
        <div className="container-left">
          <div className="star">
            <img src={stars} alt="stars" />
          </div>
          <div className="des1">Ứng dụng Mobile tích hợp với hệ thống Unicorn</div>
          <div className="des2">
            Đơn giản, dễ dùng, tiết kiệm thời gian, rút ngắn quá trình chốt đơn. Ứng dụng Unicorn
            cung cấp đầy đủ tính năng bạn cần thiết nhằm phục vụ việc bán hàng một cách thuận tiện.
          </div>
          <div>
            <img src={team_members} alt="members" />
          </div>
        </div>
      </StylesWrapperLeft>
    </Row>
  );
};
