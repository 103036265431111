const VersionStatusCon = [
  { key: "READY", value: "Chờ phát hành" },
  { key: "PUBLISH", value: "Đã phát hành" },
];

const VersionOSCon = [
  { key: "ALL", value: "Tất cả" },
  { key: "ONLY_IOS", value: "ios" },
  { key: "ONLY_ANDROID", value: "Android" },
];

export { VersionStatusCon, VersionOSCon };
