export const AccountType = {
  GET_DETAIL_ACCOUNT_REQUEST: 'GET_DETAIL_ACCOUNT_REQUEST',
  GET_DETAIL_ACCOUNT_RESPONSE: 'GET_DETAIL_ACCOUNT_RESPONSE',
  SEARCH_ACCOUNT_REQUEST: 'SEARCH_ACCOUNT_REQUEST',
  CREATE_ACCOUNT_REQUEST: 'CREATE_ACCOUNT_REQUEST',
  UPDATE_ACCOUNT_REQUEST: 'UPDATE_ACCOUNT_REQUEST',
  DELETE_ACCOUNT_REQUEST: 'DELETE_ACCOUNT_REQUEST',
  GET_ACCOUNT_DETAIL_REQUEST: 'GET_ACCOUNT_DETAIL_REQUEST',
  GET_LIST_ACCOUNT_REQUEST: 'GET_LIST_ACCOUNT_REQUEST',
  GET_LIST_DEPARTMENT_REQUEST: 'GET_LIST_DEPARTMENT_REQUEST',
  GET_LIST_POSITION_REQUEST: 'GET_LIST_POSITION_REQUEST',
  GET_LIST_SHIPPER_REQUEST: 'GET_LIST_SHIPPER_REQUEST',
  GET_LIST_EXTERNAL_SHIPPER_REQUEST: 'GET_LIST_EXTERNAL_SHIPPER_REQUEST',
  POWER_BI_EMBEDED_REQUEST: 'POWER_BI_EMBEDED_REQUEST',
  UPDATE_PASSS_REQUEST: 'UPDATE_PASSS_REQUEST',
  GET_ACCOUNT_ME: 'GET_ACCOUNT_ME',
  UPDATE_ME: 'UPDATE_ME',
  SEARCH_ACCOUNT_PUBLIC: 'SEARCH_ACCOUNT_PUBLIC',
};

export const DepartmentType = {
  SEARCH_DEPARTMENT: 'SEARCH_DEPARTMENT',
  SEARCH_DEPARTMENT_PAGING: 'SEARCH_DEPARTMENT_PAGING',
  DETAIL_DEPARTMENT: 'DETAIL_DEPARTMENT',
  CREATE_DEPARTMENT: 'CREATE_DEPARTMENT',
  UPDATE_DEPARTMENT: 'UPDATE_DEPARTMENT',
}


