import styled from "styled-components";
import { Col } from "antd";
import './../../assets/css/_variable.scss'
export const StylesWrapperRight = styled(Col)`
  padding-top: 32px;
  padding-bottom: 32px;
  @media screen and (max-width: 1280px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .container-right {
    width: 65%;
    max-width: 500px;
    height: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 500px) {
      width: 400px;
    }
  }
  .header {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: center;
    img {
      width: 220px;
      height: 40px;
    }
  }
  .yody-hello {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    margin-top: 16px;
    @media screen and (max-width: 1280px) {
      margin-top: 12px;
    }
  }

  .login {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    padding: 20px 40px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    @media screen and (max-width: 1280px) {
      padding: 0;
    }
    .forget-password {
      margin: -12px auto !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .quen-mk {
        color: #5656a2;
        cursor: pointer;
      }
    }
    .login-group {
      transform: translateY(-25%);

      @media screen and (max-width: 1280px) {
        transform: initial;
      }
      padding-top: 90px;
    }
    .login-form {
      margin-top: 20px;
      .row-form {
        margin-top: 10px;
      }
      label {
        ::before {
          content: "";
        }
        ::after {
          display: inline-block;
          margin-right: 4px;
          color: #ff4d4f;
          font-size: 14px;
          line-height: 1;
          content: "*";
        }
      }
    }
    .login-form-button {
      margin-top: 20px;
      width: 100%;
    }
    .login-title {
      text-align: center;
      color: #667085;
      padding: 0 44px;
    }
    .username.ant-input-affix-wrapper {
      padding-right: 0 !important;
    }
  }

  .copy-right {
    text-align: center;
    color: #666666;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
`;

export const StylesWrapperLeft = styled(Col)`
  background: #001529;
  .container-left {
    width: 65%;
    margin-top: 12% !important;
    max-width: 500px;
    height: 87%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1280px) {
      margin-top: 5% !important;
    }
    .star{
      width: 80px;
      height: 80px;
    }

    .header {
      display: flex;
      align-items: center;
      gap: 12px;
      justify-content: center;
      img {
        width: 120px;
      }
    }
    .logo-main {
      text-align: center;
      margin-top: 60px;
      @media screen and (max-width: 1280px) {
        margin-top: 24px;
      }
      img {
        width: 442px;
      }
    }
    .des1 {
      width: 528px;
      font-style: normal;
      font-weight: 600;
      font-size: 64px;
      line-height: 72px;
      color: #ffffff;
      text-align: left;
      margin: 0 auto;
      margin-top: 42px;
      margin-bottom: 12px;
      @media screen and (max-width: 1280px) {
        margin-top: 16px;
        margin-bottom: 8px;
      }
    }
    .des2 {
      width: 528px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      text-align: left;
      margin: 0 auto;
    }
  }
`;
