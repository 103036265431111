const HttpStatus = {
  SUCCESS: 20000000,
  UNAUTHORIZED: 40100000,
  BAD_REQUEST: 40000000,
	FORBIDDEN: 40300000,
  NOT_FOUND: 40400000,
  SERVER_ERROR: 50000000,
  BAD_GATEWAY: 50200000,
};

export {HttpStatus};
